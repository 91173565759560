@import '../../variables.scss';
@import '../../mixins.scss';

.header-main {
    height: 100px;
    background-color: rgb(255, 145, 0);

    .header-menu {
        @include container;

        display: flex;

        #logo {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 25%;

            .logo {
                display: flex;
                align-items: center;
                width: 70px;
            }
        }

        #header-contact-links {
            display: flex;
            flex-direction: column;

            .contact-info {
                align-items: flex-start;
                display: flex;
                justify-content: flex-end;



                p, a {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 10px;
                    width: auto;

                    .icon {
                        color: white;
                        padding: 1px -5px 0 5px;
                        width: 40px;
                    }
                }

                a {
                    @include headerLinkHoverAnimation();

                    .icon.envelope {
                        @include headerLinkHoverAnimation();
                    }

                    color: white;
                    text-decoration: none;
                }
            }

            #header-links {
                align-items: flex-end;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 5px;
                height: 10px;
            }
        }
    }

    .header-border {
        height: 6px;
        width: 100%;
        background: linear-gradient(0deg, rgba(136,51,55,1) 35%, rgb(178, 101, 0) 75%, rgba(255,145,0,1) 100%);
    }
}

// Media Query
