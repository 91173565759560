@import "../../mixins.scss";
@import "../../variables.scss";

.header-link-container {

    height: 50px;
    padding-left: 30px;
    width: auto;


    .header-link {
        @include headerLink();

        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-decoration: none;
    }
}
