@import '../../variables.scss';
@import '../../mixins.scss';

.footer-main {
    background-color: rgb(255, 145, 0);
    bottom: 0;
    height: 200px;

    .footer-border {
        height: 6px;
        width: 100%;
        background: linear-gradient(180deg, rgba(136,51,55,1) 35%, rgb(178, 101, 0) 75%, rgba(255,145,0,1) 100%);
    }

    .footer-menu {
        @include container;

        a {
            @include footerLinkHoverAnimation();
            color: white;
            text-decoration: none;
        }

        display: grid;
        grid-template-columns: 15% 15% 15% 55%;
        padding: 20px 0 20px 0;
        .footer-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 20px;
        }

        .footer-column-one {
            grid-column-start: 1;
        }


        .footer-column-four {
            display: flex;
            flex-direction: column;

            .copyright {
                display: flex;
                color: white;
                justify-content: flex-end;
                align-items: flex-end;
                text-decoration: none;
            }

            .social {
                display: flex;
                align-items: flex-end;
                a {
                    display: flex;
                    justify-self: flex-end;
                    align-self: flex-end;
                    padding: 0 20px 0 0;

                }
            }
        }
    }
}
