@import '../../variables.scss';
@import '../../mixins.scss';

.home {
    .img-container {
        position: absolute;
        width: 100%;
        z-index: -1;

        img {
            filter: brightness(50%)
        }
    }

    .text-container {
        display: flex;
        color: white;
        position: absolute;
        text-shadow:
        3px 3px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
        padding: 15rem 20rem;

        ul {
            display: flex;
            flex-direction: column;

            li {
                font-size: 1.5rem;
            }
        }
    }
}
