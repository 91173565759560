@import "../variables.scss";
@import "../mixins.scss";

.products {
    @include container;
    @include main;
    @include summary;

    h2 {
        text-align: center;
        padding: 2rem 0;
    }
}
