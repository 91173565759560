@import './mixins.scss';

.App {
    @include font-style(1rem);

    top: 0;
    margin: auto;
    box-sizing: border-box;
}

// Media Queries
